


























import { Component, Prop, Vue } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'

@Component({
  inheritAttrs: false,
  components: { TextInput },
})
export default class ClaimTextInput extends Vue {
  @Prop() private fieldDescription!: string
  @Prop() private prefix!: string
  @Prop() private errorMessage!: string
}
