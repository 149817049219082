






































import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import ClaimTextInput from '../../components/ClaimTextInput/index.vue'

@Component({
  components: {
    Modal,
    ClaimTextInput,
    Button,
  },
})
export default class ModalApproved extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: false }) private isDisabled!: boolean
  @Prop({ default: false }) private isError!: boolean
  @Prop({ default: 0 }) private maxCount!: number
  @Prop({ default: 0 }) private value!: number
}
